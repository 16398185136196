const htmlElementsEnabled = ['div', 'span', 'a', 'figure', 'img', 'video'];
const lazyClass: string = 'lazyloaded';

let lazyHtmlElements: HTMLDivElement[];
let lazyloadThrottleTimeout: number;

const htmlSelectors = (theClass, selectors) => {
  return selectors.map((s) => s + '.' + theClass).join(',');
};

const loadHTMLElement = (htmlElement: HTMLDivElement): void => {
  if (htmlElement.dataset.style) {
    htmlElement.style.backgroundImage = htmlElement.dataset.style
      .replace('background-image:', '')
      .replace(';', '');
    htmlElement.dataset.style = null;
  }
  htmlElement.classList.remove(lazyClass);
};

const loadImg = (img: HTMLImageElement): void => {
  if (img.dataset.src) img.src = img.dataset.src;
  img.classList.remove(lazyClass);
};

const loadVideo = (video: HTMLVideoElement): void => {
  for (let source in video.children) {
    let videoSource: HTMLVideoElement = video.children[
      source
    ] as HTMLVideoElement;
    if (
      typeof videoSource.tagName === 'string' &&
      videoSource.tagName === 'SOURCE'
    ) {
      videoSource.src = videoSource.dataset.src;
    }
  }

  const isPlaying: boolean =
    video.currentTime > 0 &&
    !video.paused &&
    !video.ended &&
    video.readyState > video.HAVE_CURRENT_DATA;

  if (!isPlaying) video.load();
  video.classList.remove(lazyClass);
};

const loaders: { [key: string]: Function } = {
  div: loadHTMLElement,
  span: loadHTMLElement,
  a: loadHTMLElement,
  figure: loadHTMLElement,
  img: loadImg,
  video: loadVideo,
};

const lazyObserver: IntersectionObserver = new IntersectionObserver(
  (entries: IntersectionObserverEntry[]): void => {
    entries.forEach((entry: IntersectionObserverEntry): void => {
      if (entry.isIntersecting) {
        const nodeName = entry.target.nodeName.toLowerCase();
        if (nodeName in loaders) {
          loaders[nodeName](entry.target);
        }
        lazyObserver.unobserve(entry.target);
      }
    });
  }
);

const offsetApply = (element, loadElement) => {
  if (element.offsetTop < window.innerHeight + window.scrollY)
    loadElement(element);
};

const initIntersectionObserver = (lazyList: HTMLElement[]) =>
  lazyList.forEach((element: HTMLElement) => lazyObserver.observe(element));

const lazyload = (): void => {
  if (lazyloadThrottleTimeout) clearTimeout(lazyloadThrottleTimeout);

  lazyloadThrottleTimeout = setTimeout((): void => {
    lazyHtmlElements.forEach((element: HTMLElement) => {
      const nodeName = element.nodeName.toLowerCase();
      if (nodeName in loaders) {
        return offsetApply(element, loaders[nodeName]);
      }
    });

    if (lazyHtmlElements.length === 0) {
      removeThrottleTimeout();
    }
  }, 20);
};

const initThrottleTimeout = () => {
  document.addEventListener('scroll', lazyload);
  window.addEventListener('resize', lazyload);
  window.addEventListener('orientationChange', lazyload);
};

const removeThrottleTimeout = () => {
  document.removeEventListener('scroll', lazyload);
  window.removeEventListener('resize', lazyload);
  window.removeEventListener('orientationChange', lazyload);
};

document.addEventListener('DOMContentLoaded', () => {
  lazyHtmlElements = Array.from(
    document.querySelectorAll(htmlSelectors(lazyClass, htmlElementsEnabled))
  );

  if (!('IntersectionObserver' in window)) {
    return initThrottleTimeout();
  }
  if (lazyHtmlElements) initIntersectionObserver(lazyHtmlElements);
});
